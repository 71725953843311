module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"BLACK WAY - Spécialiste Harley-Davidson","short_name":"BLACK WAY","start_url":"/","background_color":"#00324C","theme_color":"#00324C","display":"fullscreen","icon":"G:\\Clients\\Black Way\\Site\\src\\images\\logo-square.png"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-P7DL8MS","includeInDevelopment":false},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
